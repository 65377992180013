html {
  line-height: 1.15
}

body {
  margin: 0
}

main {
  display: block
}

h1 {
  font-size: 2em;
  margin: .67em 0
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible
}

pre {
  font-family: monospace,monospace;
  font-size: 1em
}

a {
  background-color: transparent
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted
}

b,strong {
  font-weight: bolder
}

code,kbd,samp {
  font-family: monospace,monospace;
  font-size: 1em
}

small {
  font-size: 80%
}

sub,sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline
}

sub {
  bottom: -.25em
}

sup {
  top: -.5em
}

img {
  border-style: none
}

button,input,optgroup,select,textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0
}

button,input {
  overflow: visible
}

button,select {
  text-transform: none
}

[type=button],[type=reset],[type=submit],button {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button
}

[type=button]::-moz-focus-inner,[type=reset]::-moz-focus-inner,[type=submit]::-moz-focus-inner,button::-moz-focus-inner {
  border-style: none;
  padding: 0
}

[type=button]:-moz-focusring,[type=reset]:-moz-focusring,[type=submit]:-moz-focusring,button:-moz-focusring {
  outline: 1px dotted ButtonText
}

fieldset {
  padding: .35em .75em .625em
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal
}

progress {
  vertical-align: baseline
}

textarea {
  overflow: auto
}

[type=checkbox],[type=radio] {
  box-sizing: border-box;
  padding: 0
}

[type=number]::-webkit-inner-spin-button,[type=number]::-webkit-outer-spin-button {
  height: auto
}

[type=search] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  outline-offset: -2px
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  appearance: button;
  font: inherit
}

details {
  display: block
}

summary {
  display: list-item
}

[hidden],template {
  display: none
}

html {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  background-color: #fff;
  box-sizing: border-box;
  font-size: 16px;
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
  text-rendering: optimizeLegibility
}

body {
  color: #000;
  font-size: 1em;
  font-weight: 400;
  line-height: 2
}

*,:after,:before {
  box-sizing: inherit
}

img {
  height: auto;
  max-width: 100%
}

.content blockquote:not(:last-child),.content dl:not(:last-child),.content ol:not(:last-child),.content p:not(:last-child),.content pre:not(:last-child),.content table:not(:last-child),.content ul:not(:last-child) {
  margin-bottom: 1.5em
}

.content ul {
  list-style: disc outside
}

.content ol,.content ul {
  margin-left: 2em;
  margin-top: 1em
}

.content li {
  margin-bottom: .25rem
}

.content h1,.content h2,.content h3,.content h4,.content h5,.content h6 {
  font-weight: 700;
  line-height: 1.125
}

blockquote,body,dd,dl,dt,fieldset,figure,h1,h2,h3,h4,h5,h6,hr,html,iframe,legend,li,ol,p,pre,textarea,ul {
  margin: 0;
  padding: 0
}

.content h1 {
  font-size: 3.5em;
  margin-bottom: .5em
}

.content h2 {
  font-size: 2.5em;
  margin-bottom: .5714em
}

.content h2:not(:first-child) {
  margin-top: 1.1428em
}

.content h3 {
  font-size: 1.5em;
  margin-bottom: .6666em
}

.content h3:not(:first-child) {
  margin-top: 1.3333em
}

.content h4 {
  font-size: 1.25em;
  margin-bottom: .8em
}

.content h5 {
  font-size: 1.125em;
  margin-bottom: .8888em
}

.content h6 {
  font-size: 1em;
  margin-bottom: 1em
}

.container {
  flex-grow: 1;
  margin: 0 auto;
  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;
  width: auto
}






@media(min-width: 768px) {
  .container {
      max-width:768px
  }
}

@media(min-width: 1024px) {
  .container {
      max-width:1024px
  }
}

@media(min-width: 1280px) {
  .container {
      max-width:1280px
  }
}

@font-face {
  font-display: swap;
  font-family: Jost;
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/jost/v14/92zatBhPNqw73ord4jQmfxIC7w.woff2) format("woff2");
  unicode-range: u+0100-02af,u+0304,u+0308,u+0329,u+1e00-1e9f,u+1ef2-1eff,u+2020,u+20a0-20ab,u+20ad-20cf,u+2113,u+2c60-2c7f,u+a720-a7ff
}

@font-face {
  font-display: swap;
  font-family: Jost;
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/jost/v14/92zatBhPNqw73oTd4jQmfxI.woff2) format("woff2");
  unicode-range: u+00??,u+0131,u+0152-0153,u+02bb-02bc,u+02c6,u+02da,u+02dc,u+0304,u+0308,u+0329,u+2000-206f,u+2074,u+20ac,u+2122,u+2191,u+2193,u+2212,u+2215,u+feff,u+fffd
}

@font-face {
  font-display: swap;
  font-family: Jost;
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/jost/v14/92zatBhPNqw73ord4jQmfxIC7w.woff2) format("woff2");
  unicode-range: u+0100-02af,u+0304,u+0308,u+0329,u+1e00-1e9f,u+1ef2-1eff,u+2020,u+20a0-20ab,u+20ad-20cf,u+2113,u+2c60-2c7f,u+a720-a7ff
}

@font-face {
  font-display: swap;
  font-family: Jost;
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/jost/v14/92zatBhPNqw73oTd4jQmfxI.woff2) format("woff2");
  unicode-range: u+00??,u+0131,u+0152-0153,u+02bb-02bc,u+02c6,u+02da,u+02dc,u+0304,u+0308,u+0329,u+2000-206f,u+2074,u+20ac,u+2122,u+2191,u+2193,u+2212,u+2215,u+feff,u+fffd
}

body,button,input,optgroup,select,textarea {
  font-family: Jost,BlinkMacSystemFont,-apple-system,Arial,sans-serif
}

.section {
  margin: 4rem 0
}

.button {
  background: #3054f3;
  background: linear-gradient(135deg,#EFE094 0%,#CEB162 100%);
  border: none;
  border-radius: 5px;
  box-shadow: none;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-size: 16px;
  font-weight: 400;
  font-weight: 700;
  justify-content: center;
  line-height: 1;
  padding: 1rem 2rem;
  text-align: center;
  text-decoration: none;
  white-space: nowrap
}

button {
  border: none
}

.input,.textarea,button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none
}

.input,.textarea {
  align-items: center;
  background: 0 0;
  border: 1px solid #acacac;
  border-radius: 10px;
  box-shadow: none;
  color: #000;
  display: inline-flex;
  font-size: 1rem;
  justify-content: flex-start;
  line-height: 1;
  padding: 1rem;
  position: relative;
  vertical-align: top
}

.email-feedback {
  color: #000;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase
}

.textarea {
  height: 200px
}

.form-row {
  display: flex;
  flex-direction: column
}

label.form-label {
  font-weight: 700;
  margin-bottom: 1rem
}

.form-row:not(:last-child) {
  margin-bottom: 3rem
}

.form-row.form-row--info {
  font-size: 14px
}

.form-wrapper {
  margin: 0 auto;
  max-width: 100%;
  padding-top: 1rem;
  width: 800px
}

.hero {
  background-image: linear-gradient(to top,#0f2142c4 50.11%,#1d13a447 99.9%),url('../public/hero.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center
}

.hero-col-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.hero-grid {
  align-items: center;
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(2,1fr)
}

.hero-grid {
  grid-template-columns: repeat(1, 1fr) !important;
}

.hero {
  padding: 4rem 0
}

.hero-logo img {
  display: block;
  width: 175px
}

.hero-content {
  color: #fff;
  margin: 2rem 0
}

.casinos-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: .5rem
}

.casino-nav-item {
  align-items: center;
  border: 1px solid #4575f2;
  border-radius: 30px;
  color: #fff;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  gap: .5rem;
  padding: .25rem .5rem;
  text-decoration: none
}

.casino-nav-item img {
  width: 18px
}

.opening-hours {
  background: linear-gradient(180deg,#000,#6f58fc 0.01%,#4575f2);
  border-radius: 10px;
  padding: 1rem
}

.opening-hours p {
  font-weight: 700;
  margin-bottom: .5rem!important
}

.opening-hours.content ul {
  -moz-column-count: 2;
  column-count: 2;
  -moz-column-gap: 2rem;
  column-gap: 2rem;
  font-size: 14px;
  list-style-type: none;
  margin: 0
}

.hotel-stars {
  display: block;
  height: 20px;
  margin: -1rem 0 3rem
}

.hero-col-2 img {
  display: block;
  width: 100%
}

.single .hero-grid {
  gap: 4rem
}

.single .hero-col-2 img {
  border-radius: 20px
}

.casino-hotel-card {
  background: #0d1a48;
  border-radius: 10px;
  box-shadow: 2px 10px 54px 29px rgba(100,118,147,.14);
  color: #fff;
  display: flex;
  gap: 2rem;
  padding: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center
}

.casinos-list-grid {
  display: grid;
  grid-template-columns: repeat(2,1fr);
  gap: 2rem
}

.casino-hotel-image-wrapper img {
  border-radius: 10px;
  box-shadow: 2px 10px 54px 29px rgba(111,88,252,.1);
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%
}

.stars-rating img {
  display: block;
  height: 20px
}

.excerpt-content,.stars-rating {
  margin-bottom: 1rem
}

.footer {
  background: #000;
  padding: 4rem 0;
  text-align: center
}

.footer-body {
  color: #fff
}

.footer-menu {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  list-style-type: none;
  margin: 2rem 0
}

.footer-menu a {
  color: #fff;
  font-weight: 700
}

.footer-logo {
  display: block;
  margin: 0 auto;
  width: 200px
}

.how-grid {
  align-items: center;
  background: #27262b;
  color: #fff;
  display: grid;
  gap: 4rem;
  grid-template-columns: 2fr 1fr;
  padding: 2rem
}

.how-grid,.how-image img {
  border-radius: 10px;
  box-shadow: 2px 10px 54px 29px rgba(100,118,147,.14)
}

.how-image img {
  display: block;
  width: 100%
}

.gallery-inner {
  background: #27262b;
  border-radius: 10px;
  box-shadow: 2px 10px 54px 29px rgba(100,118,147,.14);
  color: #fff;
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr 1.5fr;
  padding: 2rem
}

.gallery-main img {
  border-radius: 10px;
  display: block;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%
}

.gallery-secondary {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: auto
}

.gallery-secondary img {
  border-radius: 10px
}

.gallery-other {
  display: flex;
  flex-direction: column;
  gap: 2rem
}

.hero-button {
  margin-top: 2rem
}

@media(min-width: 1280px) {
  body.single .hero-main {
      display:none
  }
}

@media(max-width: 1279px) {
  .hero-grid {
      grid-template-columns:1fr;
      text-align: center
  }

  .hero-logo img {
      margin: 0 auto
  }

  .casinos-nav {
      justify-content: center
  }

  .hero-grid {
      position: relative
  }

  .hero-col-2 img {
      height: 100%;
      left: 50%;
      max-width: unset;
      -o-object-fit: cover;
      object-fit: cover;
      opacity: .1;
      position: absolute;
      top: 0;
      transform: translateX(-50%);
      width: 100vw
  }

  body.single .hero-col-2 img:not(.hero-main) {
      display: none
  }

  .hero-col-1 {
      padding: 2rem 0;
      position: relative;
      z-index: 1
  }

  .hero {
      padding: 0
  }

  img.hotel-stars {
      margin: -1rem auto 3rem
  }

  .opening-hours.content ul {
      -moz-column-count: 1;
      column-count: 1
  }

  .opening-hours {
      margin: 0 auto;
      max-width: 100%;
      width: 500px
  }

  .single .hero-content {
      margin-bottom: 0
  }
}

@media(max-width: 1023px) {
  .gallery-inner,.how-grid {
      grid-template-columns:1fr
  }
}

@media(max-width: 767px) {
  .casino-hotel-card {
      flex-direction:column
  }

  .casino-hotel-image {
      width: 100%
  }
}

@media(max-width: 575px) {
  .opening-hours {
      width:unset
  }

  .content h1 {
      font-size: 3em
  }
}

.hero-content.content p {
  font-size: 20px;
  text-align: center
}

.excerpt-content {
  line-height: 18px;
  font-size: 15px;
  letter-spacing: .2px
}

.footer img {
  width: 100%;
  max-width: 250px;
  margin-bottom: 8px;
  display: block;
  margin: auto
}

.footer p {
  color: #fff;
  padding-bottom: 20px
}

.footer a {
  color: #fff;
  text-decoration: none
}

.footer ul {
  padding-bottom: 20px;
  list-style-type: none;
  padding-left: 0
}

.copyright {
  color: #fff;
  text-align: center
}

@media screen and (max-width: 1024px) {
  .casinos-list-grid {
      grid-template-columns:repeat(1,1fr)!important
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
